.skp-text-cta {
  padding: 60px 0 70px;
  width: 40%;
  margin: auto; }
  @media (max-width: 991.98px) {
    .skp-text-cta {
      padding: 60px 26px;
      width: 100%; } }
  .skp-text-cta .title {
    text-transform: uppercase;
    font-weight: 700; }
  .skp-text-cta .text {
    margin-bottom: 2rem; }
    .skp-text-cta .text p {
      margin-bottom: 0; }
  .skp-text-cta a {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    border-bottom: 2px solid #000;
    text-transform: uppercase;
    width: auto;
    margin: auto; }
    .skp-text-cta a:hover {
      text-decoration: none;
      border-bottom: 2px solid transparent;
      transition: .3s; }
  @media (max-width: 991.98px) {
    .skp-text-cta .skp-link-cta {
      flex-direction: column; }
      .skp-text-cta .skp-link-cta a {
        margin-bottom: 2rem; } }
